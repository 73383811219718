import React, { Component } from "react"
import Layout from "../components/layout"
// import Croppie from 'croppie'
// import 'croppie/croppie.css'
// import Image from "../images/gatsby-icon.png"

class Game extends Component {
  state = {
    level1Text: "",
    level2Text: "",
    level3Text: "",
    heroName: "hero",
    villianName: "villian",
    cityName: "city",
    trophy: "prize",
    formStep: 1,
    // image: null,
    // croppie: null,
  }
  // componentDidMount() {
  //   this.setState({
  //     image: Image
  //   }, () => this.setUpCroppie())

    // $uploadCrop = $('#upload-demo').croppie({
    // enableExif: true,
    // viewport: {
    //     width: 200,
    //     height: 200,
    //     type: 'circle'
    // },
    // boundary: {
    //     width: 300,
    //     height: 300
    // }
// });
//     c.method(args);
  // }

  // setUpCroppie() {
  //   var el = document.getElementById('croppie');
  //   this.setState({
  //     croppie: new Croppie(el, {
  //       viewport: { width: 200, height: 260, type: 'circle' },
  //       boundary: { width: 300, height: 300 },
  //       showZoomer: true,
  //       enableOrientation: true
  //     })
  //   }, () => {
  //     this.state.croppie.bind({
  //       url: this.state.image,
  //       orientation: 1,
  //     })
  //     // this.state.croppie.result('blob').then(function(blob) {
  //     //   console.log(blob)
  //     // })
  //   })
  // }

  changeHandler(position, value) {
    this.setState({
      [position]: value,
    }, this.setState({
        level1Text: `It was a cold and stormy day when ${this.state.heroName} set out from ${this.state.cityName} in search of ${this.state.trophy}. Soon enough the evil ${this.state.villianName} was being evil`,
        level2Text: `${this.state.heroName} saw ${this.state.villianName} hiding near the ${this.state.trophy}. "Stop right there ${this.state.villianName}!" yelled ${this.state.heroName}`,
        level3Text: `${this.state.heroName} finally found the ${this.state.trophy} In the swamps outside of ${this.state.cityName} the evil ${this.state.villianName} went home and cried for 6 days. ${this.state.heroName} got ice cream`
      })
    )
  }
  handleFormStep(step){
    this.setState({
      formStep: step,
    })
  }

  render() {
    return (
      <Layout>
        {this.state.formStep === 1 ?
        <table>
            <tr>
              <th width="50%">
                <h4>Level 1 Text</h4>
                <p>It was a cold and stormy day when <span style={{color: "orange"}}>{this.state.heroName}</span> set out from <span style={{color: "orange"}}>{this.state.cityName}</span> in search of <span style={{color: "orange"}}>{this.state.trophy}</span>. Soon enough the evil <span style={{color: "orange"}}>{this.state.villianName}</span> was being evil</p>
                <h4>Level 2 Text</h4>
                <p><span style={{color: "orange"}}>{this.state.heroName}</span> saw <span style={{color: "orange"}}>{this.state.villianName}</span> hiding near the <span style={{color: "orange"}}>{this.state.trophy}</span>. "Stop right there <span style={{color: "orange"}}>{this.state.villianName}</span> !" yelled <span style={{color: "orange"}}>{this.state.heroName}</span></p>
                <h4>Level 3 Text</h4>
                <p><span style={{color: "orange"}}>{this.state.heroName}</span> finally found the <span style={{color: "orange"}}>{this.state.trophy}</span>. In the swamps outside of <span style={{color: "orange"}}>{this.state.cityName}</span> the evil <span style={{color: "orange"}}>{this.state.villianName}</span> went home and cried for 6 days. <span style={{color: "orange"}}>{this.state.heroName}</span> got ice cream</p>
              </th>
              <th width="50%">
                <form>
                  <label>Hero's Name</label>
                  <br />
                  <input className="signup-input-element" onChange={e => this.changeHandler('heroName', e.target.value)} />
                  <br />
                  <label>City's Name</label>
                  <br />
                  <input className="signup-input-element" onChange={e => this.changeHandler('cityName', e.target.value)} />
                  <br />
                  <label>Trophy's Name</label>
                  <br />
                  <input className="signup-input-element" onChange={e => this.changeHandler('trophy', e.target.value)} />
                  <br />
                  <label>Villian's Name</label>
                  <br />
                  <input className="signup-input-element" onChange={e => this.changeHandler('villianName', e.target.value)} />
                  <br />
                  <button onClick={() => this.handleFormStep(2)}>Continue</button>
                </form>
              </th>
            </tr>
          </table> : null}
          {this.state.formStep === 2 ?
          <table>
              <tr>
                <th width="50%">
                  <img src="#" />
                </th>
                <th width="50%">
                  <form>
                    <label>Hero</label>
                    <br />
                    <input type="file" className="signup-input-element" onChange={e => this.changeHandler('heroName', e.target.value)} />
                    <br />
                    <label>City's Name</label>
                    <br />
                    <input className="signup-input-element" onChange={e => this.changeHandler('cityName', e.target.value)} />
                    <br />
                    <label>Trophy's Name</label>
                    <br />
                    <input className="signup-input-element" onChange={e => this.changeHandler('trophy', e.target.value)} />
                    <br />
                    <label>Villian's Name</label>
                    <br />
                    <input className="signup-input-element" onChange={e => this.changeHandler('villianName', e.target.value)} />
                    <br />
                    <button onClick={() => this.handleFormStep(2)}>Continue</button>
                  </form>
                </th>
              </tr>
            </table> : null}
      </Layout>
    )
  }
}

export default Game
